<template>
  <section>
    <button class="btn btn-primary" v-b-modal.add-target>
      <i class="feather icon-user-plus"></i>
      <span class="targetTitle m-l-10 " style="text-transform: none">{{ $t("monitoring.add_target") }}</span>
    </button>

    <b-modal v-model="visible" id="add-target" centered hide-footer :title="this.$t('monitoring.add_target')">
      <form @submit.prevent="createTarget">
          <div class="dialog-content">
            <div class="form-group">
              <input v-model="alias" :placeholder="this.$t('monitoringLocation.title')" class="form-control" :class="{'is-invalid': this.nameInvalid}">
              <p class="text-danger" v-if="nameInvalid === 'alias_taken'">{{ $t('monitoringLocation.title_taken') }} </p>
            </div>
            <div class="form-group">
              <b-form-textarea :placeholder="this.$t('monitoring.note')"  v-model="note" style="min-height: 100px"></b-form-textarea>
            </div>

            <div class="inputContainer">
              <div class="form-group" v-if="radio === 'isdn'">
                <vue-tel-input class="inputFocused" v-model="isdnIput" :autoDefaultCountry="false" 
                :dropdownOptions="{
                  showDialCodeInList: true,
                  showDialCodeInSelection: true,
                  showFlags: true
                }" 
                :inputOptions="{
                  placeholder: $t('search.phoneNumberLabel')+' (ISDN)'
                }"
                mode="auto" type="tel" :defaultCountry="preferredCountry" styleClasses="input"
                :validCharactersOnly="true" @input="getValidate"></vue-tel-input>
              </div>


              <div class="form-group" v-else>
<!--                <input v-model="inputVal" :placeholder="this.$t('monitoring.value')" class="form-control">-->
                <input v-model="inputVal" :placeholder="`${$t('monitoring.enter_')} ${$t('monitoring.'+radio)}`" class="form-control">
              </div>
            </div>

            <div>
              <h5>{{ $t("monitoring.params") }}:</h5>
            </div>
            <div class="select-section form-radio">
              <div class="radio" v-for="(param, k) in currentSources" :key="k">
                <label style="display: flex">
                  <input type="radio" v-model="radio" :value="param.param" name="param">
                  <i class="helper"></i>
                  <div class="d-inline-block m-r-5">{{ $t(`monitoring.${param.param}`) }} <i 
                    class="feather icon-info" 
                    v-if="param.param === 'uuid'" 
                    :content="$t('monitoringLocation.uuid_description')" 
                    v-tippy="{ placement : 'top',  arrow: true}" ></i></div>
                  <div v-if="targetType !== 'location'" style="display: flex">(<div>{{param.name.join(', ')}}</div>)</div>
                </label>
              </div>
            </div>
          </div>
          <p class="text-danger" v-if="phoneInvalid">{{ $t('errors.validationphone') | capitalize }} </p>
          <footer class="d-grid gap-2 m-t-15">
            <b-button type="submit" variant="primary" class="btn"
              :disabled="$v.$invalid">{{ $t('monitoring.add') }}
              <span v-if="isLoading">
              <b-spinner small></b-spinner>
            </span>
            </b-button>
            <b-button type="button" variant="secondary"
              @click="closeDialog" class="btn">{{ $t('user.cancel') }}
            </b-button>
          </footer>
        </form>
    </b-modal>
  </section>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import { helpers, minLength, maxLength, required, numeric } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import _ from "underscore";
import filters from '@/mixins/filters'

export default {
  name: "addTarget",
  components: {
    VueTelInput
  },
  props: ['targetType'],
  data() {
    return {
      visible: false,
      alias: '',
      note: '',
      value: '',
      radio: null,
      isdnIput: '',
      inputVal: '',
      phoneInvalid: false,
      nameInvalid: null,
      preferredCountry: localStorage.getItem('country') ?
        JSON.parse(localStorage.getItem('country')).countryCode : 'UA',
      input: {},
      isLoading: false
    }
  },
  mixins: [filters],

  validations() {

    let numbercheck = helpers.regex('username', /^[a-zA-Z0-9_]+$/);
    let idCheck = helpers.regex('vk_id', /^[a-zA-Z0-9_\.]+$/);

    let validation = {
      value: {
        required
      },
      alias: {
        required,
        maxLength: maxLength(255)
      },
      note: {
        maxLength: maxLength(255)
      }
    }
    if (this.radio === 'isdn') {
      validation.value = {
        required,
        validPhone: () => typeof this.input.valid === 'undefined' ? false : this.input.valid
      };
    }
    if (this.radio === 'username') {
      validation.value = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(32),
        numbercheck
      }
    }
    if (this.radio === 'vk_id'){
      validation.value = {
        required,
        idCheck,
        maxLength: maxLength(32),
      }
    }

    return validation
  },
  computed: {
    ...mapGetters('monitoring', ['sources', 'targets']),
    ...mapGetters('auth', ['user']),

    currentSources() {
      let currentSources = []
      if(!_.isUndefined(this.sources.data)) {

        let online = this.sources.data.filter(item => item.monitoring_types.includes('online_status'))
        let locations = this.sources.data.filter(item => item.monitoring_types.includes('location'))

        let tempArr = []

        if (this.targetType === 'location'){
          tempArr = locations
        } else {
          tempArr = online
        }

        tempArr.forEach(source => {
          source.monitoring_params.forEach(param => {
            let indx = currentSources.findIndex(item => item.param === param)
            let name = source.name;
            if (name === 'VKMonitor'){
              name = "VKontakte"
            }
            if (indx > -1){
              currentSources[indx].name.push(name)
            } else {
              let index;
              switch (param){
                case 'isdn':
                  index = 0;
                  break;
                case 'username':
                  index = 1;
                  break;
                case 'vk_id':
                  index = 2;
                  break;
              }
              currentSources.push({
                name: [name],
                param: param,
                index: index
              })
            }
          })
        })
      }
      return currentSources.sort(function(a, b) {
        return a.index - b.index;
      });
    },
  },
  async mounted() {
    if(_.isUndefined(this.sources.data)) {
      await this.$store.dispatch('monitoring/getSources')
    }
    await this.defaultSource();
  },
  methods: {
    showDialog() {
      this.visible = !this.visible;
    },
    closeDialog() {
      this.visible = false;
    },
    createTarget() {
      this.isLoading = true
      let payload = {
        alias: this.alias,
        param: this.radio,
        value: this.value,
        type: this.targetType === 'location' ? 'location' : 'online_status'
      }
      if (this.note !== ''){
        payload.note = this.note;
      }

      payload = JSON.parse(JSON.stringify(payload))
      this.nameInvalid = null;
      this.$store.dispatch('monitoring/createTarget', payload).then(() => {
        this.$store.dispatch('monitoring/getTargets',{
          filters: {
            type: this.targetType === 'location' ? 'location' : 'online_status'
          }
        }).then(() => {
          this.visible = false
        })
      }).catch(e => {
        if(!_.isUndefined(e.response.data.message)) {
          if(e.response.data.message === 'validation.phone') {
            this.phoneInvalid = true;
          } else if(e.response.data.message === "The alias has already been taken.") {
            this.nameInvalid = 'alias_taken';
          }
        }
      }).finally(() => {
        this.isLoading = false;
      }) 
    },
    defaultSource() {
      const params = this.currentSources.map(item => item.param);
      if(params.includes('isdn')) {     
        this.radio = 'isdn'
      } else {
        this.radio = params[0]
      }
    },
    getValidate(inputNum, phoneObj) {
      let payload = JSON.parse(JSON.stringify(phoneObj));
      this.input = payload;
      this.value = payload.number
    }
  },
  watch: {
    visible(){
      if (!this.visible){
        this.radio = 'isdn';
        this.alias = '';
        this.note = '';
        this.value = '';
        this.isdnIput = '';
        this.inputVal = '';
        this.phoneInvalid = false;
      }
    },
    radio(){
      this.phoneInvalid = false;
      this.value = '';
      this.isdnIput = '';
      this.inputVal = '';
    },
    inputVal() {
      this.value = this.inputVal;
    },
  }
}
</script>

<style lang="scss">
  .addTarget{
    cursor: pointer;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 1rem;
    .targetTitle{
      line-height: 2rem;
      font-weight: 600;
      display: flex;
      text-align: center;
      margin-right: 1rem;
    }

  }
  .target-dialog{
    z-index: 111;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0,0%,4%,.86);
    .close{
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
    .dialog-container{
      width: 350px;
      border-radius: 6px;
      background-color: white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .dialog-title{
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        color: white;
        padding:  1rem 1.5rem;
        background-color: #4099ff;
      }
      .dialog-content{
        padding: 1.5rem;
        .inputContainer{
          margin-bottom: 1.5rem;
        }
        .select-section{
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          align-content: flex-start;
          .resultItem{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
  }
</style>